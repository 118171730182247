<template>
  <b-modal
    id="modal-add-new"
    ref="modal"
    centered
    :title="itemData && itemData.id ? $t('modules.shipping_methods.pages.edit') : $t('modules.shipping_methods.pages.add')"
    size="lg"
    hide-footer
    ok-title="Accept"
    cancel-title="Cancel"
    body-class="p-0"
    @show="onShow"
  >
    <form-wizard
      ref="refFormObserver"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('Save')"
      :back-button-text="$t('Previous')"
      :next-button-text="$t('Next')"
      :start-index="0"
      :color="$themeColors.primary"
      @on-complete="onSubmit"
    >

      <!-- account details tab -->
      <tab-content
        :title="$t('modules.shipping_methods._create.tabs.basic_settings').toString()"
        :before-change="validationBasicSettingsForm"
        :icon="itemData.id ? 'feather icon-file-text' : null"
      >
        <validation-observer
          ref="basicSettingsRules"
          tag="form"
        >
          <basic-info-tab
            ref="basicSettingsTab"
            v-model="itemData"
            :options="options"
          />
        </validation-observer>
      </tab-content>

      <!-- rates tab -->
      <tab-content
        :title="$t('modules.shipping_methods._create.tabs.shipping_rates').toString()"
        :before-change="validationShippingRatesForm"
        :icon="itemData.id ? 'feather  icon-dollar-sign' : null"
      >
        <validation-observer
          ref="shippingRatesRules"
          tag="form"
        >
          <shipping-rates-tab
            v-model="itemData"
            :cities="cities"
          />
        </validation-observer>
      </tab-content>

      <!-- TODO: Future feature -->
      <!-- company information tab -->
      <tab-content
        v-if="0 && itemData.courier && itemData.courier.provider"
        title="Shipping Company Info"
        :before-change="validationCompanyInfoForm"
      >
        <validation-observer
          ref="companyInfoRules"
          tag="form"
        >
          <!--          <company-information-tab />-->
        </validation-observer>
      </tab-content>

      <!-- integration details tab -->
      <tab-content
        v-if="0 && itemData.courier && itemData.courier.provider"
        title="Integration Info"
        :before-change="validationIntegrationSettingsForm"
      >
        <validation-observer
          ref="integrationSettingsRules"
          tag="form"
        >
          <!--          <integration-settings-tab />-->
        </validation-observer>
      </tab-content>

    </form-wizard>

  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import store from '@/store'
import shippingMethodStoreModule from '@/views/models/shipping_methods/shippingMethodStoreModule'
import {
  computed, nextTick, onUnmounted, ref,
} from 'vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import Ripple from 'vue-ripple-directive'
import ShippingRatesTab from '@/views/models/shipping_methods/add/ShippingRatesTab.vue'
import BasicInfoTab from '@/views/models/shipping_methods/add/BasicInfoTab.vue'
import { toast } from '@core/utils/utils'
import { $themeColors } from '@themeConfig'
// import CompanyInformationTab from '@/views/models/shipping_methods/add/CompanyInformationTab.vue'
// import IntegrationSettingsTab from '@/views/models/shipping_methods/add/IntegrationSettingsTab.vue'

export default {
  components: {
    ValidationObserver,
    FormWizard,
    TabContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ShippingRatesTab,
    BasicInfoTab,
    // CompanyInformationTab,
    // IntegrationSettingsTab,
  },
  directives: {
    Ripple,
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: null,
    },
    preselectedCourierCode: {
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true,
      default: Array,
    },
    cities: {
      type: Array,
      required: false,
      default: Array,
    },
  },
  computed: {
    $themeColors() {
      return $themeColors
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs

    // if (this.itemData.id) {
    //   this.fetchItemData()
    // }

    // Remount in case of component-reload
    if (!store.hasModule(this.STORE_MODULE_NAME)) store.registerModule(this.STORE_MODULE_NAME, shippingMethodStoreModule)
  },
  methods: {
    validationBasicSettingsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.basicSettingsRules.validateWithInfo().then(({ success, errors, fields }) => {
          if (success) {
            resolve(true)
          } else {
            const failedFields = Object.keys(fields).filter(key => fields[key].failed)
            if (failedFields.length) {
              toast('warning', this.$t('message.fill_required_fields'))
              this.focusOnTabsOfErrors(failedFields, this.$refs.basicSettingsTab.$refs)
              reject()
            } else {
              resolve(true)
            }
          }
        })
      })
    },
    validationCompanyInfoForm() {
      return new Promise((resolve, reject) => {
        this.$refs.companyInfoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationIntegrationSettingsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.integrationSettingsRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationShippingRatesForm() {
      return new Promise((resolve, reject) => {
        this.$refs.shippingRatesRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'shipping_methods'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, shippingMethodStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // Data definition
    const refs = ref({})
    const modal = ref()
    const emptyItemData = {
      name: {},
      notes: {},
      courier: {
        code: null,
        label: null,
      },
      rates: [
        {
          cities: [],
          is_fixed_cost: true,
          fixed_cost: null,
          weight_threshold: null,
          extra_weight_cost: null,
          extra_weight_grams: null,
        },
      ],
      is_courier_controls_rates: false,
      is_active: true,
    }

    const itemData = ref({})
    const isLoading = ref(true)
    const rates = ref([])
    const activeCourier = computed(() => {
      const courierCode = itemData.value.courier.code || props.preselectedCourierCode || 'CUSTOM'
      return props.options.filter(o => o.code === courierCode)?.[0] || {
        code: courierCode,
        name: null,
      }
    })

    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(emptyItemData))
      isLoading.value = false
      ctx.emit('update:item-id', null)

      itemData.value.courier.code = activeCourier.value.code
      itemData.value.courier.label = activeCourier.value.name
    }

    resetItemData()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    const {
      validationErrors, fetch, performSave,
      focusOnTabsOfErrors,
    } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      itemData,
      () => {
        modal.value.hide()
        ctx.emit('refetch-data')
        ctx.emit('update:show', false)
        ctx.emit('onSubmit')
      },
      null,
      null,
      () => {
        // refs.value.value.sidebar.hide()
        itemData.value = JSON.parse(JSON.stringify(emptyItemData))

        ctx.emit('update:show', false)
      },
    )

    const fetchItem = () => {
      if ((itemData.value.id !== props.itemId) && props.itemId !== null) {
        itemData.value.id = props.itemId
        return fetch().then(() => refFormObserver.value.activateAll())
      }
      return null
    }

    const onShow = () => {
      resetForm()
      fetchItem()

      nextTick(() => {
        refFormObserver.value.reset()
      })

      // refs.value.value.refFormObserver.reset()
    }

    const onSubmit = () => {
      isLoading.value = true
      performSave(true,
        () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        })
    }

    return {
      STORE_MODULE_NAME,
      itemData,
      rates,
      fetchItem,
      validationErrors,
      onShow,
      onSubmit,

      // Refs
      modal,
      refFormObserver,
      getValidationState,
      focusOnTabsOfErrors,
      resetForm,

      refs,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
[dir] .modal-content .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  box-shadow: 0 3px 6px 0 rgba(73, 227, 112, 0.4);
}
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
