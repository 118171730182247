<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <h5
        v-t="'modules.shipping_methods._create.tabs.basic_settings'"
        class="mb-0"
      />
      <small
        v-t="'modules.shipping_methods._create.tabs.basic_settings:description'"
        class="text-muted"
      />
    </b-col>
    <b-col md="6">
      <i-input
        v-model="value.courier"
        :errors="validationErrors.courier_code"
        type="select"
        title="modules.shipping_methods.inputs.courier"
        :options="courierOptions"
        :clearable="true"
        label="courier_name"
        :get-option-label="option => option.label ? option.label : $t(`modules.shipping_methods.providers.${option.code}`)"
        required
        :disabled="!!value.id"
      >
        <template #list-footer>
          <p
            v-t="`modules.shipping_methods.inputs.courier:footer`"
            class="pl-50 pt-50"
          />
        </template>
      </i-input>
    </b-col>
    <b-col md="6">

      <i-input
        id="itemName"
        ref="itemName"
        v-model="value.name"
        :errors="validationErrors.name"
        type="text"
        title="modules.shipping_methods.inputs.name"
        description="modules.shipping_methods.inputs.name:description"
        required
        :translatable="true"
      />

    </b-col>
    <b-col md="12">
      <i-input
        v-model="value.notes"
        :errors="validationErrors.notes"
        type="textarea"
        title="modules.shipping_methods.inputs.note"
        description="modules.shipping_methods.inputs.note:description"
        rows="2"
        :translatable="true"
      />
    </b-col>
    <b-col md="6">
      <i-input
        v-model="value.is_active"
        :errors="validationErrors.is_active"
        type="switch"
        title="inputs.is_active"
        description="inputs.is_active:description"
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="XIcon" />
        </span>
      </i-input>
    </b-col>
    <b-col md="6">
      <i-input
        v-model="value.is_cod_allowed"
        :errors="validationErrors.is_cod_allowed"
        type="switch"
        title="modules.shipping_methods.inputs.is_cod_allowed"
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="XIcon" />
        </span>
      </i-input>
    </b-col>
  </b-row>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    options: {
      type: Array,
      required: true,
      default: Array,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
  },
  setup(props, ctx) {
    const customShippingMethodOption = {
      code: 'CUSTOM',
      name: null,
    }

    const availableOptions = props.options
      .filter(o => o.canInstall)
      .map(o => ({ code: o.code, label: o.name }))

    availableOptions.unshift(customShippingMethodOption)

    return {
      courierOptions: availableOptions,
      validationErrors: {},
    }
  },
}
</script>
