<template>
  <b-card
    no-body
    :class="{'open': visible}"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header

      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(true)"
    >
      <slot name="header">
        <span class="lead collapse-title w-100">
          <b-form-radio
            name="shipping_option"
            class="mt-1 w-100"
            :value="true"
            :checked="visible"
          >
            <div class="w-100">
              <div class="d-flex justify-content-between">
                <h6 class="text-base font-weight-medium">
                  {{ title }}
                </h6><h6 class="text-base font-weight-bold justify-content-end">
                  {{ charges ? $n(charges / 100, 'currency') : $t('Free') }}
                </h6>
              </div>
            </div>
          </b-form-radio>
        </span>

      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse, BInputGroup, BFormInput, BFormGroup, BFormRadio,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormRadio,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    charges: {
      type: Number,
      required: true,
    },
    notes: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      input: false,
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>


<style lang="scss">
.shipping-options-list .custom-control-label{
  width: 100%
}
</style>
