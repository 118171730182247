<template>
  <div>
    <shipping-methods-list-loader
      v-if="isLoading"
    />
    <div
      v-else
      class="list-view product-checkout mt-0"
    >
      <shipping-method-list
        :is-loading.sync="isLoading"
        :items="items"
        :suggestions="suggestions"
        :cities="cities"
        @item-update="fetchItemsList"
      />
      <div>
<!--        <general-settings-->
<!--          :value="settings"-->
<!--        />-->
        <preview
          ref="preview"
          :cities="cities"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onUnmounted, ref } from 'vue'
import ShippingMethodsListLoader from '@/views/loaders/ShippingMethodsListLoader.vue'
import Preview from '@/views/models/shipping_methods/list/Preview.vue'
import store from '@/store'
import shippingMethodStoreModule from '@/views/models/shipping_methods/shippingMethodStoreModule'
import ShippingMethodList from './ShippingMethodList.vue'

export default {
  components: {
    Preview,
    ShippingMethodsListLoader,
    // SFC
    ShippingMethodList,
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'shipping_methods'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, shippingMethodStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const isLoading = ref(false)
    const items = ref([])
    const suggestions = ref([])
    const cities = ref([])
    const settings = ref({})

    const fetchItemsList = () => {
      // ctx.refs.preview.reset()
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/fetchAll`)
        .then(response => {
          isLoading.value = false
          items.value = response.data.data
          suggestions.value = response.data.suggestions
          cities.value = response.data.cities
          settings.value = response.data.settings
        })
    }
    fetchItemsList()

    return {
      isLoading,
      items,
      suggestions,
      cities,
      settings,

      // Actions
      fetchItemsList,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/shipping-management.scss';
</style>
