<template>
  <div>
    <b-row
      ref="form"
      class="repeater-form"
    >
      <!--      :style="{height: trHeight}"-->

      <div
        v-if="false"
        class="col-12"
      >
        <b-form-group
          :label="$t(`modules.shipping_methods.inputs.is_courier_controls_rates`)"
          label-for="is_courier_controls_rates"
        >
          <b-form-checkbox
            id="is_courier_controls_rates"
            v-model="value.is_courier_controls_rates"
            checked="true"
            class="custom-control-primary"
            button-variant="danger"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <material-icon
                icon="bolt"
                size="16"
              />
            </span>
            <span class="switch-icon-right">
              <material-icon
                icon="close"
                size="16"
              />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </div>
      <template v-if="!isAutoRatesFeatureAvailable || !value.is_courier_controls_rates">
        <div class="col-12">
          <hr
            v-if="isAutoRatesFeatureAvailable"
            class="my-2"
          >
          <label
            v-if="isAutoRatesFeatureAvailable"
            v-t="$t(`modules.shipping_methods._list.manually_select_shipping_rates`)"
          />
          <label
            v-else
            v-t="$t(`modules.shipping_methods._list.shipping_rates`)"
          />
          <b-alert
            variant="info"
            show
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                v-t="`modules.shipping_methods._messages.duplicated_cities_note`"
                class="ml-25"
              />
            </div>
          </b-alert>
        </div>
        <draggable
          v-model="value.rates"
          handle=".draggable-rate-handle"
          tag="div"
          class="col-12 list-group list-group-flush cursor-move"
        >
          <b-row
            v-for="(item, index) in value.rates"
            :id="item.id"
            :key="`item-${index}`"
            ref="row"
            class="rates-item"
          >
            <b-col
              md="1"
              class="d-flex justify-content-center flex-column"
            >
              <material-icon
                class="draggable-rate-handle"
                icon="drag_indicator"
                color="#a0a0a0"
              />
              <!--          @mouseover="rowUnderHover = row.item.id"-->
              <!--          @mouseout="rowUnderHover = null"-->
            </b-col>

            <b-col
              md="9"
              class="cursor-default"
            >
              <b-row>
                <!-- Pricing Mode -->
                <b-col md="2">
                  <b-form-group
                    :label="$t(`modules.shipping_methods.inputs.is_fixed_cost`)"
                    :label-for="`is_fixed_cost_${index}`"
                  >
                    <b-form-checkbox
                      :id="`is_fixed_cost_${index}`"
                      v-model="item.is_fixed_cost"
                      checked="true"
                      class="custom-control-primary"
                      button-variant="danger"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <material-icon
                          icon="push_pin"
                          size="16"
                        />
                      </span>
                      <span class="switch-icon-right">
                        <material-icon
                          icon="weight"
                          size="16"
                        />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <!-- Cities -->
                <b-col
                  md="10"
                  :lg="item.is_fixed_cost ? 7 : 10"
                >
                  <i-input
                    v-model="item.cities"
                    type="select"
                    multiple="multiple"
                    title="modules.shipping_methods.inputs.cities"
                    :options="[{ id: 'country', name: $t('modules.shipping_methods._create.all_cities') }, ...cities]"
                    :clearable="true"
                    label="name"
                    :reduce="i => i.id"
                    required
                    @input="onSelectedCitiesChange(index)"
                  />
                  <!--        :errors="validationErrors.courier_code"-->
                </b-col>

                <!-- Fixed Cost -->
                <b-col
                  :md="item.is_fixed_cost ? 12 :6"
                  lg="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(`modules.shipping_methods.inputs.fixed_cost`)"
                    rules="required:min:0"
                  >
                    <b-form-group
                      :label="$t(`modules.shipping_methods.inputs.fixed_cost`)"
                      :label-for="`fixed_cost_${index}`"
                    >
                      <b-input-group
                        :append="getLocaleCurrency()"
                        :class="{'is-invalid': errors && errors.length}"
                      >
                        <b-form-input
                          :id="`fixed_cost_${index}`"
                          v-model="item.fixed_cost"
                          type="number"
                          placeholder="5"
                          step="1"
                          required
                          min="0"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-input-group>

                      <small
                        v-if="item.is_fixed_cost"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Weight Cost -->
                <template
                  v-if="!item.is_fixed_cost"
                >
                  <b-col
                    md="6"
                    lg="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t(`modules.shipping_methods.inputs.weight_threshold`)"
                      rules="required|min:0"
                    >
                      <b-form-group
                        :label="$t(`modules.shipping_methods.inputs.weight_threshold`)"
                        :label-for="`weight_threshold_${index}`"
                      >
                        <b-input-group
                          append="KG"
                          :class="{'is-invalid': errors && errors.length}"
                        >
                          <b-form-input
                            :id="`weight_threshold_${index}`"
                            v-model="item.weight_threshold"
                            type="number"
                            placeholder="1"
                            step="1"
                            min="0"
                            required
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <!--                      <small class="text-danger">{{ errors[0] }}</small>-->
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    lg="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t(`modules.shipping_methods.inputs.extra_weight_cost`)"
                      rules="required|min:0"
                    >
                      <b-form-group
                        :label="$t(`modules.shipping_methods.inputs.extra_weight_cost`)"
                        :label-for="`extra_weight_cost_${index}`"
                      >
                        <b-input-group
                          :append="getLocaleCurrency()"
                          :class="{'is-invalid': errors && errors.length}"
                        >
                          <b-form-input
                            :id="`extra_weight_cost_${index}`"
                            v-model="item.extra_weight_cost"
                            type="number"
                            placeholder="2"
                            step="1"
                            min="0"
                            required
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <!--                      <small class="text-danger">{{ errors[0] }}</small>-->
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    md="6"
                    lg="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t(`modules.shipping_methods.inputs.extra_weight_grams`)"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t(`modules.shipping_methods.inputs.extra_weight_grams`)"
                        :label-for="`extra_weight_grams_${index}`"
                      >
                        <b-input-group
                          append="KG"
                          :class="{'is-invalid': errors && errors.length}"
                        >
                          <b-form-input
                            :id="`extra_weight_grams_${index}`"
                            v-model="item.extra_weight_grams"
                            type="number"
                            placeholder="1"
                            step="1"
                            required
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>
                        <!--                      <small class="text-danger">{{ errors[0] }}</small>-->
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </template>
              </b-row>
            </b-col>

            <!-- Remove Button -->
            <b-col
              md="2"
              class="mb-50 d-flex justify-content-center flex-column cursor-default"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                :disabled="value.rates.length < 2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </draggable>
      </template>
    </b-row>
    <b-row
      v-if="!value.is_courier_controls_rates"
      class="text-center align-items-center align-content-center d-flex"
    >
      <b-button
        class="m-auto"
        variant="outline-primary"
        @click="addItem"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span v-t="'modules.shipping_methods._create.add_new_rate'" />
      </b-button>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider } from 'vee-validate'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback, BButton,
  BFormCheckbox, BInputGroup, BInputGroupAppend, BAlert,
} from 'bootstrap-vue'
import { ref } from 'vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import { getLocaleCurrency } from '@core/utils/utils'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    draggable,
    BAlert,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    cities: {
      type: Array,
      required: false,
      default: Array,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // this.initTrHeight()
  },
  methods: {
    getLocaleCurrency,
    addItem() {
      this.value.rates.push(JSON.parse(JSON.stringify(this.emptyRateItem)))

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    removeItem(index) {
      if (this.value.rates.length > 1) {
        this.value.rates.splice(index, 1)
      }
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      // this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.form.scrollHeight)
      // })
    },
    onSelectedCitiesChange(index) {
      const rate = this.value.rates[index]
      if (rate.cities.length < 2) {
        return
      }
      const countryItemIndex = rate.cities.findIndex(i => i === 'country')
      if (countryItemIndex > -1) {
        if (countryItemIndex === 0) {
          rate.cities.splice(0, 1)
        } else {
          rate.cities = ['country']
        }
      }
    },
  },
  setup(props, ctx) {
    // Data definition
    const emptyRateItem = {
      id: null,
      cities: [],
      is_fixed_cost: true,
      fixed_cost: null,
      weight_threshold: null,
      extra_weight_cost: null,
      extra_weight_grams: null,
    }

    const rates = ref([
      emptyRateItem,
    ])

    return {
      emptyRateItem,
      rates,
      isAutoRatesFeatureAvailable: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  transition: .35s height;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 600px;
}
</style>

<style lang="scss">
//[dir] .custom-control-primary .custom-control-input ~ .custom-control-label::before {
//  border-color: #00cfe8;
//  background-color: #00cfe8;
//}
//.custom-switch .custom-control-label .switch-icon-right {
//  color: #fff;
//}

[dir="ltr"] .form-control.is-invalid[type=number] {
  padding: 0.438rem 1rem;
}
[dir] .form-control.is-invalid[type=number] {
  background: unset;
}
</style>
