import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import shippingMethodModel from './shippingMethodModel'
import shippingOptionModel from './shippingOptionModel'

const {
  fetchAll,
  fetchOne,
  create,
  update,
  updateAttribute,
  deleteOne,
} = useModelStoreModule(shippingMethodModel, true)

const {
  fetchAll: fetchAllOptions,
} = useModelStoreModule(shippingOptionModel)

export default {
  name: 'shipping_methods',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll: (ctx, queryParams) => fetchAll(ctx, { with_suggestions: true, with_cities: true, ...queryParams }),
    fetchOne,
    create,
    update,
    updateAttribute,
    deleteOne,

    fetchOptions: (ctx, queryParams) => fetchAllOptions(ctx, queryParams),
  },
}
